import React, {
  createContext, useContext, useState, useMemo, useEffect
} from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({children, userData}) => {
  const [user, setUser] = useState(userData || {});
  const loggedIn = useMemo(() => Object.hasOwn(user, 'id'), [user]);

  const login = user => {
    setUser(user);
  }
  const logout = () => {
    setUser({});
  }

  useEffect(() => {
    if (user !== userData) {
      setUser(userData)
    }
  }, [userData]);

  return (
    <AuthContext.Provider
      value={{loggedIn: loggedIn, user: user, login: login, logout: logout}}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext);
}
