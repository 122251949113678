import React from 'react';
import {Backdrop, CircularProgress} from '@mui/material';

const Loading = ({open}) => {
  return (
    <Backdrop open={open} sx={{zIndex: 1350}}>
      <CircularProgress color="secondary" />
    </Backdrop>
  );
}
export default Loading;