import React from 'react';
import {Typography, Link, Box} from '@mui/material';
import {
  useRouteError,
  Link as RRLink,
  isRouteErrorResponse,
} from 'react-router-dom';

const ErrorElement = () => {
  const error = useRouteError();
  console.error(error);
  const isRouteError = isRouteErrorResponse(error);

  return (
    <Box sx={{p: 2}}>
      <Typography align="center" variant="h4" gutterBottom>
        Oops!
      </Typography>
      {isRouteError &&
        <Typography align="center" gutterBottom>
          {error.status}
        </Typography>
      }
      {error.message === 'Failed to fetch' || !navigator.onLine ?
        <Typography align="center" gutterBottom>
          Could not connect. Please check your internet connection.
        </Typography>
      :
        <Typography align="center" gutterBottom>
          {error.statusText || error.message}
        </Typography>
      }
      <Link
        display="block"
        align="center"
        component={RRLink}
        to="/"
      >
        Go to home page
      </Link>
    </Box>
  )
}
export default ErrorElement;
