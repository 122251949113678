import React from 'react';
import {useLoaderData, Outlet} from 'react-router-dom';
import {AuthProvider} from '../auth'

const Root = () => {
  const response = useLoaderData();

  return <AuthProvider userData={response}><Outlet /></AuthProvider>;
}
export default Root;
