import React from 'react';
import {Tabs, Tab} from '@mui/material';
import {Link} from 'react-router-dom';

const Nav = ({tabIndex, setTabIndex, user, fullSize, closeDrawer}) => {
  const isAttendant = user?.is_attendant;
  return (
    <Tabs
        component="nav"
        value={tabIndex}
        indicatorColor={fullSize ? 'primary' : 'secondary'}
        onChange={(e, value) => {
          setTabIndex(value);
          if (!fullSize) {
            closeDrawer();
          }
        }}
        orientation={fullSize ? "horizontal" : 'vertical'}
        sx={{
          maxWidth: '100%',
          flexGrow: fullSize ? 1 : 0,
          '.MuiTab-root': {
            color: 'rgb(255 255 255 / .90)',
            alignItems: fullSize ? 'center' : 'flex-start',
          },
          // '.Mui-selected': {
          //   color: '#fff'
          // },
          '& .MuiTab-flexContainer': {
            alignItems: 'stretch'
          }
        }}
      >
        <Tab component={Link} to="/" label="Home" value="home" />
        <Tab component={Link}
          to="/appointments/self"
          label="Appointments"
          value="appointments"
        />
        <Tab
          component={Link}
          to="/availables"
          label="Availables"
          value="availables"
          sx={{display: isAttendant ? 'flex': 'none'}}
        />
        <Tab
          component={Link}
          to="/appointments-by-date"
          label="Appointments by date"
          value="byDate"
          sx={{display: isAttendant ? 'flex' : 'none'}}
        />
        <Tab component={Link} to="/contact" label="Contact" value="contact" />
    </Tabs>
  );
}
export default Nav;
