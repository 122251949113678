import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import minMax from 'dayjs/plugin/minMax';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(isBetween);
dayjs.extend(customParseFormat);
dayjs.extend(minMax);
dayjs.extend(utc);
dayjs.extend(timezone);
// dayjs.tz.setDefault('America/New_York');

export {dayjs};

export const combineDateTime = (date, time) => {
  return dayjs(joinISODate(dateToString(date), timeToString(time))).toDate();
}

export const roundToMinute = date => (
  dayjs(date).startOf('minute').toDate()
);

export const timeStringToDate = (timeString, date) => {
  const workingDate = date ? date : new Date();
  const result =  dayjs([dateToString(workingDate), timeString].join('T')).toDate();
  return result;
}

export const dateToString = date => (
  dayjs(date).format('YYYY-MM-DD')
)

const timeToString = time => (
  dayjs(time).toISOString().split('T')[1]
)

export const apiToTimeString = (apiString, date) => {
  const components = apiString.split(':');
  const hour = parseInt(components[0]);
  const minute = parseInt(components[1]);
  let workingDate = date ? dayjs(date) : dayjs();
  workingDate = workingDate.hour(hour).minute(minute).startOf('minute');
  return dayjs(workingDate).format('h:mm A');
}

export const timeStringToApi = (timestring) => {
  const date = dayjs(timestring, 'h:mm A');
  return `${date.format('HH:mm')}:00`;
}

export const joinISODate = (date, time) => (
  `${dateToString(date)}T${timeToString(time)}`
)

export const inInterval = (startDate, endDate, workingDate) => {
  return dayjs(workingDate).isBetween(startDate, endDate, 'minute', [])
}

export const intervalsOverlap = (interval1, interval2) => {
  return (
    dayjs(interval1.start).isBetween(interval2.start, interval2.end, 'minute', '[)')
    || dayjs(interval1.end).isBetween(interval2.start, interval2.end, 'minute', '(]')
  )
}

export const urlB64ToUint8Array = base64String => {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
