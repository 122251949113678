import React from 'react';
import {Menu, MenuItem} from '@mui/material';
import {Link as RRLink} from 'react-router-dom';
import {host} from '../fetching';

const AuthGroup = ({loggedIn, open, handleClose, anchorEl, isStaff}) => {
  const protocol = window.location.protocol;
  let signedInGroup = [
    <MenuItem
      key="logout"
      onClick={handleClose}
      component={RRLink}
      to="/accounts/logout"
    >
      Logout
    </MenuItem>
  ]
  if (isStaff) {
    signedInGroup = [
      <MenuItem
        key="admin"
        onClick={handleClose}
        component='a'
        href={`${protocol}//${host}/admin/`}
      >
        Admin Site
      </MenuItem>,
      ...signedInGroup,
    ]
  }
  return (
    <Menu
      component="nav"
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      slotProps={{
        paper: {sx: {bgcolor: 'primary.main', color: '#fff'}
      }}}
    >
      {!loggedIn ? [
        (
          <MenuItem
            key="login"
            onClick={handleClose}
            component={RRLink}
            to="/accounts/login"
          >
            Login
          </MenuItem>
        ),
        (
          <MenuItem
            key="signup"
            onClick={handleClose}
            component={RRLink}
            to="/accounts/signup"
          >
            Signup
          </MenuItem>
        ),
      ] : signedInGroup
      }
    </Menu>
  );
}
export default AuthGroup;
