import React, {useEffect, useState} from 'react';
import {Button, Typography, Box} from '@mui/material';
import HttpsIcon from '@mui/icons-material/Https';
import useBanquest from '../useBanquest';


const cardInputStyle = (
  'height: 40px;box-sizing: border-box;'
  + 'background-color:rgba(255, 255, 255, 0.3);'
  + 'border: .5px solid rgba(0,0,0,0.23); border-radius: 4px;'
  + 'padding: 8.5px 14px; color: rgba(0, 0, 0, 0.87);'
  + 'font-family: Roboto, Helvetica, Arial, sans-serif;'
);

const Banquest = ({setCardData, formRef, reset, setReset}) => {
  const cardForm = useBanquest();
  const [cardError, setCardError] = useState('');


  const getToken = async () => {
    const cardFormData = await cardForm.getData();
    const error = cardFormData.error;
    if (error) {
      setCardError(error);
      return;
    }
    if (cardError) {
      setCardError('');
    }
    try {
      const result = await cardForm.getNonceToken();
      console.log(result);
      setCardData(result);
      return result;
    } catch (ex) {
      setCardError(ex.message);
      console.error(ex);
      throw ex;
    }
  }

  useEffect(() => {
    if (cardForm) {
      cardForm.mount('#payment-container', {fields: {zip: true}});
      cardForm.setStyles({
        container: 'display: flex;flex-flow:row wrap; gap: 8px;',
        card: cardInputStyle,
        expiryMonth: cardInputStyle + 'width: 56px;',
        expiryYear: cardInputStyle + 'width: 56px;',
        cvv2: cardInputStyle + 'width: 64px',
        avsZip: cardInputStyle,
        floatingLabelsPlaceholder: 'letter-spacing: 0.00938em;',
      });
    }
  }, [cardForm]);

  useEffect(() => {
    if (cardForm) {
      cardForm.on('input', () => {
        if (cardError) {
          setCardError('');
        }
      })
    }
  }, [cardForm, cardError]);

  useEffect(() => {
    if (reset) {
      cardForm.resetForm();
      setReset(false);
    }
  }, [reset]);

  return (
    <>
    <Box
      sx={{
        width: '100%',
        mt: 3, mb: 2,
        '@media (min-width: 352px)': {
          maxHeight: '150px'
        },
        '@media (min-width: 584px)': {
          maxHeight: '104px'
        },
      }}
    >
      <Typography variant="h6" component="h3" sx={{mb: 2}}>
        Card Details
      </Typography>
      <Box id="payment-container" />
    </Box>
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
      <Button
        variant="contained" 
        color="primary"
        startIcon={<HttpsIcon />}
        onClick={async e => {
          e.preventDefault();
          if (formRef.current.checkValidity()) {
            try {
              await getToken();
            } catch {}
          }
          formRef.current.reportValidity();
        }}
      >
        Process Payment
      </Button>
      <Typography variant="body2" color="error">
        {cardError}
      </Typography>
    </Box>
    </>
  );
}
export default Banquest;
