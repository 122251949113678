import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
// Supports weights 100-900
import '@fontsource-variable/raleway';
import '@fontsource/roboto';
import theme from './theme';
import {
  rootLoader,
  dataLoader,
  homeLoader,
  contactsLoader,
  daysAppointmentsLoader,
  availablesLoader,
  allAvailablesLoader,
  appointmentsLoader,
  makeAppointmentLoader,
  loggedInLoader,
  paymentLoader
} from './loaders';
import Root from './components/Root';
import App from './components/App';
import ErrorElement from './components/ErrorElement';
import Payment from './components/Payment';

const router = createBrowserRouter([
  {
    element: <Root />,
    errorElement: <ErrorElement />,
    loader: rootLoader,
    // shouldRevalidate: ({actionResult, currentUrl}) => {
    //   return (
    //     actionResult?.ok || actionResult?.status === 401
    //     || actionResult?.status === 403
    //     || currentUrl.pathname === '/accounts/login'
    //     || currentUrl.pathname === '/accounts/logout'
    //   );
    // },
    children: [
      {
        element: <App />,
        loader: dataLoader,
        shouldRevalidate: () => false,
        layout: true,
        id: 'App',
        errorElement: <ErrorElement />,
        children: [
          {
            errorElement: <ErrorElement />,
            children: [
              {
                index: true,
                loader: homeLoader,
                lazy: () => import('./components/Home'),
              },
              {
                loader: contactsLoader,
                path: 'contact',
                lazy: () => import('./components/Contact'),
              },
              {
                path: 'payment/:method?',
                loader: paymentLoader,
                shouldRevalidate: () => false,
                lazy: () => import('./components/Payment'),
              },
              {
                path: 'appointments/:role/*',
                loader: appointmentsLoader,
                lazy: () => import('./components/Appointments'),
                children: [
                  {
                    path: 'make-appointment',
                    loader: makeAppointmentLoader,
                    errorElement: <ErrorElement />,
                    lazy: () => import('./components/MakeAppointment'),
                  },
                ],
              },
              {
                path: 'availables/:dialog?',
                loader: availablesLoader,
                lazy: () => import('./components/Availables'),
              },
              {
                path: 'all-availables',
                loader: allAvailablesLoader,
                lazy: () => import('./components/AllAvailables'),
              },
              {
                path: 'appointments-by-date',
                loader: daysAppointmentsLoader,
                lazy: () => import('./components/DaysAppointments'),
              },
              {
                path: 'accounts/*',
                errorElement: <ErrorElement />,
                lazy: () => import('./components/Accounts'),
                children: [
                  {
                    path: 'login',
                    loader: loggedInLoader,
                    lazy: () => import('./components/LoginForm')
                  },
                  {path: 'signup', lazy: () => import('./components/Signup')},
                  {path: 'logout', lazy: () => import('./components/Logout')},
                  {path: 'confirm-email/:key', lazy: () => import('./components/ConfirmEmail')},
                  {path: 'reset-password', lazy: () => import('./components/ChangePassword')},
                  {
                    path: 'confirm-reset-password/:uid/:token',
                    lazy: () => import('./components/ConfirmChangePassword')
                  },
                ],
              },
            ]
          },
        ],
      },
    ],
  },
]);

const root = createRoot(document.getElementById('root'));

root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </StyledEngineProvider>
);

if ('serviceWorker' in navigator) {
  const {Workbox} = await import('workbox-window');
  const wb = new Workbox('/sw.js');
  wb.register();
}
