import React, {useMemo} from 'react';
import GooglePayButton from '@google-pay/button-react';


const paymentRequestDict={
  apiVersion: 2,
  apiVersionMinor: 0,
  allowedPaymentMethods: [
    {
      type: 'CARD',
      parameters: {
        allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
        allowedCardNetworks: ['MASTERCARD', 'VISA', 'AMEX', 'DISCOVER'],
        assuranceDetailsRequired: true,
      },
      tokenizationSpecification: {
        type: 'PAYMENT_GATEWAY',
        parameters: {
          gateway: 'acceptblue',
          gatewayMerchantId: process.env.GOOGLE_GATEWAY_MERCHANT_ID,
        },
      },
    },
  ],
  merchantInfo: {
    merchantId: process.env.GOOGLE_MERCHANT_ID,
    merchantName: 'BBJ Mikvah',
  },
  transactionInfo: {
    totalPriceStatus: 'FINAL',
    totalPriceLabel: 'Total',
    totalPrice: '100.00',
    currencyCode: 'USD',
    countryCode: 'US',
    checkoutOption: "COMPLETE_IMMEDIATE_PURCHASE",
  },
  emailRequired: true,
}

const GooglePay = ({amount, setGpayData, ...props}) => {
  const paymentRequest = useMemo(() => {
    if (amount) {
      paymentRequestDict.transactionInfo.totalPrice = amount
    }
    return paymentRequestDict
  }, [amount]);

  return (
    <GooglePayButton
      buttonColor="white"
      environment={process.env.GOOGLE_PAY_ENVIRONMENT || 'TEST'}
      paymentRequest={paymentRequest}
      buttonType="pay"
      onLoadPaymentData={response => {
        setGpayData(response);
      }}
      {...props}
    />
  );
}
export default GooglePay;
