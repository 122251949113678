import React, {useState, useRef, useCallback} from 'react';
import {
  Link as RRLink, Outlet, useLocation,
  useLoaderData, useNavigation
} from 'react-router-dom';
import {Box, Link, useMediaQuery, IconButton, Drawer,
  Avatar, Typography, Button, CssBaseline} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Nav from './Nav';
import AuthGroup from './AuthGroup';
import HeroImage from '../../images/hero.webp';
import BackgroundImage from '../../images/background.webp';
import Loading from './Loading';
import {useAuth} from '../auth';

const styles = {
  content: {
    px: '1em',
    py: 10,
    margin: '0 auto 0',
    maxWidth: 750,
    '@media(max-width: 800px)': {
      py: 5,
    },
  },
  loadingContent: {
    px: '1em',
    py: 10,
    margin: '0 auto 0',
    maxWidth: 750,
    '@media(max-width: 800px)': {
      py: 5,
    },
    opacity: '0.25',
    transition: 'opacity 200ms',
    transitionDelay: '200ms',
  },
  banner: {
    backgroundImage: `linear-gradient(to right bottom, rgba(78, 94, 101, 0.40), rgba(78, 94, 101, 0.15)), url(${HeroImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: '#fff',
    height: '500px',
    px: '120px',
    '@media (max-width: 1200px)': {
      px: '48px',
    },
    '@media (max-width: 1080px)': {
      px: '32px',
    },
    '@media (max-width: 320px)': {
      px: '16px',
    },
  },
  background: {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundRepeat: 'repeat',
    backgroundPosition: 'center',
    backgroundSize: '400px auto'
  },
  appbar: {
    backgroundColor: 'hsla(0, 0% 0)',
  },
  toolbar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: 'center',
    columnGap: '16px',
    width: '100%',
  },
  header1: {
    color: 'primary.contrastText',
  },
  link: {
    textDecorationLine: 'none',
    color: 'primary.contrastText',
  },
  loginButton: {
    color: 'primary.contrastText',
  },
  authGroup: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    gap: 2,
  },
  greeting: {
    alignSelf: 'center',
  },
  hero_content: {
    display: 'flex',
    flexDirection: 'column',
    height: '90%',
    display: 'flex',
    maxWidth: '500px',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
};

const initTabs = pathname => {
  if (pathname.includes('/appointments/')) {
    return 'appointments';
  } else if (pathname.includes('/availables')) {
    return 'availables';
  } else if (pathname.includes('/appointments-')) {
    return 'byDate';
  } else if (pathname.includes('/contact')) {
    return 'contact';
  } else {
    return 'home';
  }
}

const jsonLD = JSON.stringify({
  '@context': 'https://schema.org',
  '@type':"Organization",
  name: 'BBJ Mikvah',
  url: 'https://www.bbjmikvah.org',
  email: 'bbjmikvah@gmail.com',
  telephone: '+1-912-744-9087',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '5444 Abercorn St',
    addressLocality: 'Savannah',
    addressRegion: 'GA',
    postalCode: '31405',
    addressCountry: 'USA'
  },
  image: `${window.location.origin}${HeroImage}`,
  'parentOrganization': {
    '@type': 'Organization',
    name: 'Bnai Brith Jacob Synagogue',
    email: 'bbjacoboffice@gmail.com',
    telephone: '+1-912-354-7721',
    url: 'https://www.bbjsynagogue.com',
    address: {
      '@type': 'PostalAddress',
      streetAddress: '5444 Abercorn St',
      addressLocality: 'Savannah',
      addressRegion: 'GA',
      postalCode: '31405',
      addressCountry: 'USA'
    }
  }
  });

const App = (props) => {
  const {pathname} = useLocation();
  const [tabIndex, setTabIndex] = useState(initTabs(pathname));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [accountsOpen, setAccountsOpen] = useState(false);
  const fullSize = useMediaQuery('(min-width: 1024px)');
  const accountsRef = useRef(null);
  const navigation = useNavigation();
  const {user, logout, loggedIn} = useAuth();

  const setTab = useCallback((value) => {
    if (tabIndex !== value) {
      setTabIndex(value);
    }
  }, [tabIndex]);

  // useEffect(() => {
  //   if (history.location.pathname.includes('/availables')) {
  //     setIndex('availables');
  //   } else if (history.location.pathname.includes('/appointments')) {
  //     setIndex('appointments');
  //   }
  // }, []);

  return (
    <Box sx={{minHeight: '100vh'}}>
      <CssBaseline />
      <Box sx={styles.banner} component="header">
        <Box sx={styles.toolbar}>
          <Box sx={{display: 'flex', alignItems: 'center', gap: '16px'}}>
          {!fullSize && <IconButton
            onClick={() => setDrawerOpen(drawerOpen => !drawerOpen)}
            aria-label="Open Navigation"
            sx={{fontSize: '32px', color: 'white'}}
          >
            <MenuIcon sx={{fontSize: '32px'}}/>
          </IconButton>}
        <Link component={RRLink} to="/" sx={styles.link}>
          <Typography variant="h5" component="h1">
            BBJ Mikvah
          </Typography>
        </Link>
          {fullSize ? <Nav
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            user={user}
            fullSize={true}
          /> :
          <Drawer
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            PaperProps={{
              sx: {
                backgroundColor: 'primary.dark',
                pt: '16px',
              },
            }}
          >
            <Nav
              tabIndex={tabIndex}
              setTabIndex={setTabIndex}
              user={user}
              fullSize={false}
              closeDrawer={() => setDrawerOpen(false)}
            />
          </Drawer>}
          </Box>
          <Box
            sx={{
              display: 'flex', flexDirection: 'row', alignItems: 'center'
            }}
          >
          <Typography
            variant="body2"
            sx={{
              '@media (max-width: 299px)': {display: 'none'}
            }}
          >
            {loggedIn && ("Welcome " + user?.name)}
          </Typography>
          <IconButton
            ref={accountsRef}
            onClick={() => setAccountsOpen(oldState => !oldState)}
            size="large"
            aria-label="Open Accounts Menu"
          >
            <Avatar variant="rounded" sx={{bgcolor: 'primary.main'}}>
              {loggedIn ?
                user?.name.charAt(0).toUpperCase() :
                <AccountCircleIcon />
              }
            </Avatar>
          </IconButton>
          <AuthGroup
            open={accountsOpen}
            handleClose={() => setAccountsOpen(false)}
            loggedIn={loggedIn}
            isStaff={user?.is_staff}
            anchorEl={accountsRef.current}
          />
          </Box>
        </Box>
        <Box sx={styles.hero_content}>
          <Typography variant="h3" component="h1">
            BBJ Mikvah of Savannah
          </Typography>
          <Typography variant="subtitle1" component="p">
            Savannah Womens' Mikvah
          </Typography>
          <Button
            variant="contained"
            component={RRLink}
            to="/appointments/self/make-appointment"
            sx={{mt: 1}}
          >
            Make Appointment
          </Button>
        </Box>
      </Box>
      <Box sx={styles.background} component="main">
        <Box
          sx={navigation.state === 'loading' ? styles.loadingContent : styles.content}
        >
          <Outlet
            context={{
              loggedIn: loggedIn, logout: logout, setTab: setTab
            }}
          />
        {!pathname.includes('/payment') && <Button
          component={RRLink}
          to='/payment'
          variant="contained"
          color="secondary"
          sx={{mt: 12}}
        >
          Donate or make a payment
        </Button>}
      </Box>
    </Box>
    <Box
      component="footer"
      sx={{
        bgcolor: 'primary.dark',
        padding: 4,
        '@media (max-width: 320px)': {
          padding: 2
        }
      }}
    >
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        gap: '16px',
      }}
    >
      <Box
        component="section"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1',
          minWidth: '200px',
        }}
      >
      <Typography variant="h6" color="white" component="h2">
        BBJacob Mikvah
      </Typography>
      <Typography variant="body2" sx={{color: "white"}}>
        Savannah Womens' Mikvah
      </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1',
          minWidth: '168px',
          '@media (max-width: 999px)': {
            display: 'none',
          },
        }}
      />
      <Box
        component="section"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1',
          minWidth: '168px',
        }}
      >
        <Typography variant="body1" color="white">
          Phone
        </Typography>
        <Link
          variant="body2"
          href="tel:9127449078"
          sx={{
            color: "white",
            '@media (max-width: 800px)': {
              textDecoration: 'underline',
            }
          }}
        >
          (912)&nbsp;744-9078
        </Link>
      </Box>
      <Box
        component="section"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1',
          minWidth: '168px'
        }}
      >
      <Typography variant="body1" color="white">
        Email
      </Typography>
      <Link
        variant="body2"
        href="mailto:bbjmikvah@gmail.com"
        sx={{
          color: "white",
          '@media (max-width: 800px)': {
            textDecoration: 'underline'
          }
        }}
      >
        bbjmikvah@gmail.com
      </Link>
      </Box>
      <Box
        component="section"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1',
          minWidth: '168px',
        }}
      >
      <Typography variant="body1" color="white">
        Mailing Address
      </Typography>
      <Typography variant="body2" sx={{color: "white"}}>
        5444 Abercorn St
      </Typography>
      <Typography variant="body2" sx={{color: "white"}}>
        Savannah, Ga 31405
      </Typography>
      </Box>
    </Box>
      <Typography variant="caption" paragraph sx={{color: "white", mt: 5, mb: 0}}>
        &copy; 2024 Congregation Bnai Brith Jacob
      </Typography>
      <Loading
        open={navigation.state === 'submitting'}
      />
    </Box>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: jsonLD}}
      />
    </Box>
  );
}
export default App;
