import React, {useEffect, useState, useRef} from 'react';
import {
  Box, Typography, TextField, Button, InputAdornment, MenuItem,
  Dialog, DialogTitle, DialogContent, DialogActions,
  SvgIcon, Tabs, Tab
} from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import {
  useParams, useSubmit, useNavigate, useLoaderData, useActionData
} from 'react-router-dom';
import {post} from '../fetching';
import Banquest from './Banquest';
import GooglePay from './GooglePay';
import GPayMark from '../../images/google-pay-mark-cropped.svg';

const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});


const styles = {
  line: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
}

const GPayIcon = (props) => (
  <SvgIcon
    {...props}
    component={GPayMark}
    inheritViewBox
    sx={{width: 'auto'}}
  />
);

export const action = async ({request, params}) => {
  const method = params.method || 'card';
  const formData = await request.formData();
  const {description, notes, ...data} = Object.fromEntries(formData);
  data.description = notes ? `${description} - ${notes}` : description;
  const response = await post(
    `payment/${method}/`,
    data
  );
  if (response.ok || response.status === 400) {
    return response;
  }
  throw response;
}

const Payment = (props) => {
  const method = useParams().method || 'card';
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState('');
  const [openReceived, setOpenReceived] = useState(false);
  const [gPayData, setGPayData] = useState({});
  const [cardData, setCardData] = useState({});
  const [reset, setReset] = useState(false);
  const navigate = useNavigate();
  const submit = useSubmit();
  const actionData = useActionData();
  const formRef = useRef(null);
  const {items, prices} = useLoaderData();
  // const paymentPost = usePost(
  //   'payment/card/',
  //   {
  //     name: form.name,
  //     email: form.email,
  //     amount: form.amount,
  //     description: form.description,
  //     avs_address: form.address,
  //     nonce: form.cardData?.nonce,
  //     avs_zip: form.cardData?.avsZip,
  //     expiry_month: form.cardData?.expiryMonth,
  //     expiry_year: form.cardData?.expiryYear,
  //   }
  // );

  // const gPayPost = usePost(
  //   'payment/google/',
  //   {
  //     amount: form.amount,
  //     email: gPayData.email,
  //     token: gPayData.paymentMethodData?.tokenizationData?.token,
  //     accountVerified: gPayData.paymentMethodData?.info?.assuranceData?.accountVerified,
  //     cardHolderAuthenticated: gPayData.paymentMethodData?.info?.assuranceData?.cardHolderAuthenticated,
  //     description: form.description,
  //     name: form.name,
  //   }
  // );

  const closeDialog = () => {
    setOpenReceived(false);
    if (method === 'card') {
      setReset(true);
      setCardData({});
    } else if (method === 'google') {
      setGPayData({});
    }
    setDescription('');
    setAmount('');
    formRef.current.reset();
  }

  const handleSubmit = async event => {
    event.preventDefault();
    if (event.target.checkValidity()) {
      try {
        await getToken();
      } catch {}
    }
  }

  useEffect(() => {
    if (
      cardData?.nonce || gPayData.paymentMethodData?.tokenizationData?.token
    ) {
      submit(new FormData(formRef.current), {method: 'post'});
    }
  }, [cardData, gPayData]);

  useEffect(() => {
    if (actionData?.status_code === 'A') {
      setOpenReceived(true);
    }
  }, [actionData]);

  useEffect(() => {
    if (prices[description]) {
      setAmount(prices[description]);
    } else {
      setAmount('')
    }
  }, [description]);

  return (
    <>
    <Typography variant="h5" component="h2" align="center" gutterBottom>
      Donations and Payments
    </Typography>
    <Box sx={{mt: 3, mb: 4}}>
      <Typography variant="h6" component="h3" sx={{mb: 2}}>
        Payment Method
      </Typography>
      <Tabs
        value={method === 'card' ? '' : method}
        onChange={(e, value) => {
          navigate(`/payment${value ? `/${value}` : ''}`);
        }}
        indicatorColor="secondary"
        TabIndicatorProps={{sx: {display: 'none'}}}
        sx={{
          gap: 1,
          '& .Mui-selected': {
            backgroundColor: 'secondary.light',
          },
          '& button': {
            minWidth: '150px',
            borderRadius: '10px',
          },
        }}
      >
        <Tab
          icon={<CreditCardIcon />}
          iconPosition="start"
          label="Card"
          value=""
          sx={{textTransform: 'capitalize'}}
        />
        <Tab
          icon={<GPayIcon />}
          label="Google Pay"
          iconPosition="start"
          value="google"
          sx={{textTransform: 'capitalize'}}
        />
      </Tabs>
    </Box>
    <Box component="form" onSubmit={handleSubmit} ref={formRef}>
      <Typography variant="h6" component="h3" sx={{mb: 2}}>
        Transaction Details
      </Typography>
      <Box sx={{display: "flex", gap: 3, flexFlow: 'row wrap'}}>
        <TextField
          variant="outlined"
          value={description}
          name="description"
          label="Description"
          onChange={e => {
            setDescription(e.target.value);
          }}
          size="small"
          autoFocus
          select
          required
          sx={{minWidth: '295px', textTransform: 'capitalize'}}
        >
          {items.map(({name, price}) => (
            <MenuItem
              key={name} value={name} sx={{textTransform: 'capitalize'}}
            >
              {name}{price ? ` - ${price}` : ''}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          variant="outlined"
          name="name"
          label={`Name${method === 'card' ? ' on card' : ''}`}
          autoComplete="name"
          size="small"
          required
          autoFocus
        />
        {method === 'card' && <TextField
          variant="outlined"
          type="email"
          name="email"
          label="Email"
          autoComplete="email"
          size="small"
          required
        />}
        <TextField
          variant="outlined"
          type="number"
          value={amount}
          onChange={e => setAmount(e.target.value)}
          name="amount"
          label="Amount"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>
          }}
          inputProps={{
            min: '0', step: '.25', readOnly: Boolean(prices[description])
          }}
          size="small"
          required
          sx={{maxWidth: '136px'}}
        />
        <TextField
          variant="outlined"
          type="text"
          size="small"
          label="Notes"
          name="notes"
          multiline
          minRows="1"
          maxRows="5"
        />
        <input
          type="hidden"
          name="token"
          value={
            gPayData.paymentMethodData?.tokenizationData?.token
            || cardData.nonce
            || ''
          }
        />
        {method === 'card' ?
        <>
        <input
          type="hidden"
          name="avs_zip"
          value={cardData.avsZip || ''}
        />
        <input
          type="hidden"
          name="expiry_month"
          value={cardData.expiryMonth || ''}
        />
        <input
          type="hidden"
          name="expiry_year"
          value={cardData.expiryYear || ''}
        />
        <Banquest
          setCardData={setCardData}
          formRef={formRef}
          reset={reset}
          setReset={setReset}
        />
      </> :
        <input
          type="hidden"
          name="email"
          value={gPayData.email || ''}
        />
      }
      </Box>
      {method === 'google' && <Box sx={{my: 2}}>
        <GooglePay
          amount={amount}
          display={method === 'google'}
          onClick={e => {
            if (!formRef.current.checkValidity()) {
              e.preventDefault();
              formRef.current.reportValidity();
            }
          }}
          setGpayData={setGPayData}
        />
      </Box>}
      <Typography variant="body2" color="error">
        {actionData?.error_message || ''}
      </Typography>
      <Dialog open={openReceived} onClose={closeDialog}>
        <DialogTitle>Thank you for your payment!</DialogTitle>
        <DialogContent>
          {method === 'card' &&
            <Box sx={styles.line}>
              <Typography>Name on card</Typography>
              <Typography>
                {actionData?.transaction?.card_details.name}
              </Typography>
            </Box>
          }
          <Box sx={styles.line}>
            <Typography>Amount</Typography>
            <Typography>
              {
                USDollar.format(actionData?.transaction?.amount_details.amount)
              }
            </Typography>
          </Box>
          <Box sx={styles.line}>
          <Typography>Payment Method</Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '4px',
              }}
            >
            {method === 'card' && <>
              <CreditCardIcon />
              <Typography display="inline-block">
                Credit card
              </Typography>
            </>}
            {method === 'google' && <>
              <GPayIcon />
              <Typography display="inline-block">
                Google Pay
              </Typography>
            </>}
            </Box>
          </Box>
          <Box sx={styles.line}>
            <Typography>Card</Typography>
            <Typography>
              {actionData?.card_type}:
              ********{actionData?.last_4}
            </Typography>
          </Box>
          <Box sx={styles.line}>
            <Typography>Status</Typography>
            <Typography>
              {actionData?.status}
            </Typography>
          </Box>
          <Box sx={styles.line}>
            <Typography>Reference#</Typography>
            <Typography>
              {actionData?.reference_number}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contain"
            onClick={closeDialog}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
    </>
  );
}
Payment.displayName = 'Payment';
export {Payment as Component};
 