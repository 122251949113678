import {redirect} from 'react-router-dom';
import {get} from './fetching';
import {dayjs} from './utils';

export const rootLoader = async () => {
  const response = await get('logged-in/?user=user');
  if (response.ok || response.status === 401 || response.status === 403) {
    return response;
  }
  throw response;
}

export const dataLoader = async () => {
  try {
    const response = await get('data/');
    if (response.ok || response.status === 401 || response.status === 403) {
      const data = await response.json();
      return {response: response, data: data}
    }
    throw response;
  } catch (err) {
    throw err;
  }
};

export const daysAppointmentsLoader = async ({request}) => {
  const url = new URL(request.url);
  let date = url.searchParams.get('date')
  date = date || dayjs().format('YYYY-MM-DD');
  const response = await get(
    `appointments/date/${date}/`
  );
  if (response.ok) {
    const appointments = await response.json();
    return {appointments: appointments, date: date};
  } if (response.status === 401) {
    return redirect('/accounts/login');
  }
  throw response;
}

export const appointmentsLeaderLoader =  async ({params, request}) => {
  if (params.role === 'leader') {
    const result = await daysAppointmentsLoader(
      {request: request, params: params}
    );
    return result;
  }
  return null;
};

export const homeLoader = async () => {
  const articles = await get('content/pages/home/');
  return articles;
};

export const contactsLoader = async () => {
  const articles = await get('content/pages/contact/');
  return articles;
};

export const availablesLoader = async ({params, request}) => {
  const availablesRes = await get('availables/?attendant=user');
  const unavailablesRes = await get('unavailables/?attendant=user');
  if (
    availablesRes.status === 401 || unavailablesRes.status === 401
  ) {
    return redirect('/accounts/login');
  }
  if (availablesRes.ok && unavailablesRes.ok) {
    const availables = await availablesRes.json();
    const unavailables = await unavailablesRes.json();
    return {availables: availables, unavailables: unavailables};
  }
  throw availablesRes;
};

export const allAvailablesLoader = async ({request}) => {
  const url = new URL(request.url);
  const weekday = url.searchParams.get('weekday') || 'all'
  const response = await get(`availables/?weekday=${weekday || 'all'}`);
  if (response.ok) {
    const availables = await response.json();
    return {availables: availables, weekday: weekday}
  } if (response.status === 401) {
    return redirect('/accounts/login');
  }
  throw response;
}

export const appointmentsLoader = async ({request, params}) => {
  const {role} = params;
  let response;
  switch (role) {
    case 'self':
      response = await get('appointments/?user=user');
      break;
    case 'attendant':
      response = await get('appointments/?attendant=user');
      break;
    case 'leader':
      const url = new URL(request.url);
      let date = url.searchParams.get('date')
      date = date || dayjs().format('YYYY-MM-DD');
      response = await get( `appointments/date/${date}/`);
      if (response.status === 401) {
        return redirect('/accounts/login');
      }
      if (response.ok) {
        const appointments = await response.json();
        return {appointments: appointments, date: date};
      }
      throw response
  }
  if (response.ok) {
    return response;
  } if (response.status === 401) {
    return redirect('/accounts/login');
  }
  throw response;
}

export const makeAppointmentLoader = async ({request, params}) => {
  const url = new URL(request.url);
  let date = url.searchParams.get('date');
  date = date || dayjs().add(1, 'd').format('YYYY-MM-DD');
  const response = await get(`date-data/${date}/?user=user`);
  if (response.status === 401) {
    return redirect('/accounts/login');
  }
  const dateData = await response.json();
  if (params.role === 'leader') {
    const usersResponse = await get('users/');
    const users = await usersResponse.json();
    return {dateData: dateData, date: dayjs(date), users: users}
  }
  if (response.ok) {
    return {dateData: dateData, date: dayjs(date)};
  }
  throw response;
}

export const loggedInLoader = async ({request}) => {
  const response = await get('logged-in/');
  if (response.status === 401 || response.status === 403) {
    return {status: response.status};
} if (response.ok) {
    return redirect('/');
  }
  throw response;
}

export const paymentLoader = async ({request, params}) => {
  const response = await get('payment/items/');
  if (response.ok) {
    const data = await response.json();
    const obj = {};
    for (const item of data) {
      if (item.price) {
        obj[item.name] = item.price;
      }
    }
    return {items: data, prices: obj};
  }
  throw response;
}
